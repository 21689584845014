// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HcUpf8gAsUYkALrj5f0P-g\\=\\={max-width:570px;margin:unset;text-align:left;box-sizing:border-box;padding:17px 0;font-size:12px}", "",{"version":3,"sources":["/app/client/components/Disclosure/Disclosure.styl"],"names":[],"mappings":"AAEA,4BACE,eAAW,CACX,YAAQ,CACR,eAAY,CACZ,qBAAY,CACZ,cAAS,CACT,cAAW","file":"Disclosure.styl","sourcesContent":["@import '../../styles/vars';\n\n:local(.global) {\n  max-width: 570px;\n  margin: unset;\n  text-align: left;\n  box-sizing: border-box;\n  padding: 17px 0;\n  font-size: 12px;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global": "HcUpf8gAsUYkALrj5f0P-g=="
};
module.exports = ___CSS_LOADER_EXPORT___;
