// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sxeYq7eJ-U\\+-gDnTWEEZ\\+Q\\=\\={max-width:570px;text-align:center;box-sizing:border-box;padding-bottom:35px;}.sxeYq7eJ-U\\+-gDnTWEEZ\\+Q\\=\\= a,.sxeYq7eJ-U\\+-gDnTWEEZ\\+Q\\=\\= img{border:none;outline:none}.sxeYq7eJ-U\\+-gDnTWEEZ\\+Q\\=\\= img{width:125px}.sxeYq7eJ-U\\+-gDnTWEEZ\\+Q\\=\\= h1{font-family:'Lora',serif;text-transform:none;color:var(--oldBlack)}.img-whitelabel-svb{width:250px !important}.img-whitelabel-emburse_spend{height:32px;width:auto !important}", "",{"version":3,"sources":["/app/client/components/layouts/LogoContainer/LogoContainer.styl"],"names":[],"mappings":"AAAA,8BACE,eAAW,CACX,iBAAY,CACZ,qBAAY,CACZ,mBAAgB,CAAA,CAEhB,kEAEE,WAAQ,CACR,YAAS,CAGX,kCACE,WAAO,CAGT,iCACE,wBAAmB,CACnB,mBAAgB,CAChB,qBAAqB,CAIzB,oBACE,sBAAO,CAGT,8BACE,WAAQ,CACR,qBAAO","file":"LogoContainer.styl","sourcesContent":[":local(.global) {\n  max-width: 570px;\n  text-align: center;\n  box-sizing: border-box;\n  padding-bottom: 35px;\n\n  a,\n  img {\n    border: none;\n    outline: none;\n  }\n\n  img {\n    width: 125px;\n  }\n\n  h1 {\n    font-family: 'Lora', serif;\n    text-transform: none;\n    color: var(--oldBlack);\n  }\n}\n\n.img-whitelabel-svb {\n  width: 250px !important;\n}\n\n.img-whitelabel-emburse_spend {\n  height: 32px;\n  width: auto !important;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global": "sxeYq7eJ-U+-gDnTWEEZ+Q=="
};
module.exports = ___CSS_LOADER_EXPORT___;
