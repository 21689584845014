import * as React from 'react';

import * as classNames from 'classnames';

import { Alert, AlertProps, Grid, Spacer, Typography } from '@emburse/embark-core';

import { LogoContainer } from '@Client/components/layouts/LogoContainer';

import * as style from './EmburseAccountsLeftContentContainer.styl';


/**
 * A component for the left panel content on pages that mimic the appearance of the Emburse Accounts login page
 * Probably only useful as a child of EmburseAccountsPageContainer
 * @param props.alertProps Props to pass to the Alert component (optional).  There will be no Alert if this is not provided
 * @param props.title The title to display at the top of the left panel (optional)
 * @param props.children The content to display
 * @returns
 */
export const EmburseAccountsLeftContentContainer: React.FC<{
  className?: string;
  alertProps?: AlertProps;
  title?: React.ReactNode;
  children: React.ReactNode;
}> = ({ className, alertProps, title, children }) => {
  return (
    <div className={classNames(style.global, className)}>
      <Grid container direction={'column'} spacing={2} justifyContent={'center'} style={{ width: '400px' }}>
        <Grid item>
          <div className={'logoContainer'}>
            <LogoContainer style={{ padding: '0px' }} />
          </div>
          {!alertProps && <Spacer size={60} direction="vertical" />}
        </Grid>
        {alertProps && (
          <Grid item>
            <Alert {...alertProps} />
          </Grid>
        )}
        {title && (
          <Grid item>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </div>
  );
};
